<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 395px">
        <field name="delegacion_id" widget="m2o" label="Deleg." width="165px" style="top: 9px; left: 10px"
          :readonly="mode == 'edit'" />
        <field name="ndoc" widget="char" searchable label="Nº Documento" width="110px" style="top: 9px; left: 189px" />
        <field name="fecha" widget="date" type="date" default="today" label="Fecha" width="100px"
          style="top: 9px; left: 307px" />
        <field name="almacen_id" widget="m2o" label="Almacen" width="235px" style="top: 9px; left: 415px"
          :readonly="mode == 'edit'" :filter="formData.delegacion_id
              ? [['delegacion_id', '=', formData.delegacion_id.codigo]]
              : null
            " @change="onChangeAlmacen" />
        <r-tabs ref="main-tabs" style="top: 55px; height: 340px" :buttons="['General', 'Líneas']">
          <div class="tab">
            <field name="seleccion" widget="select" label="Selección de artículos" :options="{
              Global: 'global',
              Individual: 'individual',
              Familia: 'familia',
              Sección: 'seccion',
              Proveedor: 'proveedor',
              Marca: 'marca',
            }" default="articulo" width="250px" style="top: 10px; left: 5px" :readonly="mode == 'edit' || !formData.almacen_id || readonlySeleccion
  " @change="onChangeSeleccion" />
            <field name="familia_id" widget="m2o" label="Familia" width="375px" style="top: 10px; left: 270px"
              :readonly="mode == 'edit' || !formData.almacen_id" v-show="formData.seleccion == 'familia'" />
            <field name="seccion_id" widget="m2o" label="Sección" width="375px" style="top: 10px; left: 270px"
              :readonly="mode == 'edit' || !formData.almacen_id" v-show="formData.seleccion == 'seccion'" />
            <field name="proveedor_id" widget="m2o" label="Proveedor" width="375px" style="top: 10px; left: 270px"
              labelProp="nombre_comercial" :readonly="mode == 'edit' || !formData.almacen_id"
              v-show="formData.seleccion == 'proveedor'" />
            <field name="marca_id" widget="m2o" label="Marca" width="375px" style="top: 10px; left: 270px"
              :readonly="mode == 'edit' || !formData.almacen_id" v-show="formData.seleccion == 'marca'" />
            <div style="
                position: absolute;
                top: 45px;
                left: 10px;
                width: 636px;
                padding: 3px;
                color: #dc8787;
                font-size: 0.8em;
              " v-if="formData.seleccion">
              <span v-if="formData.seleccion == 'global'">
                La selección de artículos global regularizará el inventario de
                TODOS los artículos de la base de datos. Se establecerá a cero
                el inventario de los artículos que no hayan sido registrados en
                una entrada física de inventario. Es posible regularizar varias
                entradas físicas de inventario.
              </span>
              <span v-if="formData.seleccion == 'individual'">
                La selección de artículos individual solamente regularizará el
                inventario de los artículos registrados en una entrada física de
                inventario. Es posible regularizar varias entradas físicas de
                inventario.
              </span>
              <span v-if="formData.seleccion == 'familia'">
                La selección de artículos por familia regularizará el inventario
                de TODOS los artículos de la familia seleccionada. Se
                establecerá a cero el inventario de los artículos de la familia
                que no hayan sido registrados en una entrada física de
                inventario. Es posible regularizar entradas físicas de
                inventario con distintas familias seleccionadas.
              </span>
              <span v-if="formData.seleccion == 'seccion'">
                La selección de artículos por seccion regularizará el inventario
                de TODOS los artículos de la seccion seleccionada. Se
                establecerá a cero el inventario de los artículos de la seccion
                que no hayan sido registrados en una entrada física de
                inventario. Es posible regularizar entradas físicas de
                inventario con distintas secciones seleccionadas.
              </span>
              <span v-if="formData.seleccion == 'marca'">
                La selección de artículos por marca regularizará el inventario
                de TODOS los artículos de la marca seleccionada. Se establecerá
                a cero el inventario de los artículos de la marca que no hayan
                sido registrados en una entrada física de inventario. Es posible
                regularizar entradas físicas de inventario con distintas marcas
                seleccionadas.
              </span>
              <span v-if="formData.seleccion == 'proveedor'">
                La selección de artículos por proveedor regularizará el
                inventario de TODOS los artículos del proveedor seleccionada. Se
                establecerá a cero el inventario de los artículos del proveedor
                que no hayan sido registrados en una entrada física de
                inventario. Es posible regularizar entradas físicas de
                inventario con distintos proveedores seleccionadas.
              </span>
            </div>
            <field name="observaciones" widget="text" label="Observaciones" width="636px" height="100px"
              style="top: 100px; right: 10px" />
          </div>
          <div class="tab">
            <field ref="lineas" name="lineas" widget="handsontable" :height="295" :width="655" :minRows="12"
              style="top: 0px; left: 0px; width: 660px" customprop="docs_customprop" :readonly="mode == 'edit'" :columns="[
                { name: 'ean13', header: '|||||||||' },
                {
                  name: 'articulo_id',
                  header: 'Cód.',
                  noOpen: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  showCode: false,
                },
                { name: 'descripcion', header: 'Artículo', readOnly: true },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]" />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Entrada de inventario físico",
      dbAdapter: "entrada_inventario",
      primary: "ndoc",
      sequence: { name: "entrada_inventario" },
      defaultData: {
        delegacion_id: self.app.config.delegacion_defecto || null,
        //almacen_id: self.app.config.almacen_defecto || null,
        fecha: new Date().yyyymmdd(),
      },
      cachedArticulosCodigo: {},
      cachedArticulosEan13: {},
      readonlySeleccion: false,
    };
  },
  methods: {
    onChangeSeleccion() {
      var self = this;
      self.$set(self.formData, "familia_id", null);
      self.$set(self.formData, "seccion_id", null);
      self.$set(self.formData, "proveedor_id", null);
      self.$set(self.formData, "marca_id", null);
      //self.$set(self.formData, "lineas", []);
    },
    rendererRojoNegativo(instance, td, row, col, prop, value, cellProperties) {
      let h = instance;
      if (value != 0) td.style.color = "red";
      else td.style.color = "grey";
      Handsontable.renderers.NumericRenderer.apply(this, arguments);
    },
    onChangeAlmacen() {
      var self = this;
      if (!self.formData.almacen_id?.codigo) return;
      window.DB.getList("entrada_inventario", {
        limit: 1,
        order: "create_date",
        orderDir: "DESC",
        filter: [
          ["almacen_id", "=", self.formData.almacen_id.codigo],
          ["regularizacion_id", "IS NULL"],
        ],
        fields: ["seleccion"],
      }).then((res) => {
        if (parseInt(res.count)) {
          self.$set(self.formData, "seleccion", res.data[0].seleccion);
          self.readonlySeleccion = true;
        } else {
          self.readonlySeleccion = false;
        }
      });
    },
  },
  mounted() {
    var self = this;

    self.$refs.lineas.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          let val = (c) => h.getDataAtRowProp(row, c) || 0;

          if (
            (prop == "ean13" || prop == "articulo_id") &&
            newVal &&
            oldVal != newVal &&
            source != "auto"
          ) {
            if (prop == "ean13" && newVal in self.cachedArticulosEan13) {
              var p = Promise.resolve({
                count: 1,
                data: [
                  self.$utils.misc.clone(self.cachedArticulosEan13[newVal]),
                ],
              });
            } else if (
              prop == "articulo_id" &&
              newVal in self.cachedArticulosCodigo
            ) {
              p = Promise.resolve({
                count: 1,
                data: [
                  self.$utils.misc.clone(self.cachedArticulosCodigo[newVal]),
                ],
              });
            } else {
              p = window.DB.getList("articulo", {
                limit: 1,
                filter: [
                  prop == "articulo_id" ? "codigo" : "ean13",
                  "=",
                  newVal,
                ],
                depth: 0,
                fields: [
                  "codigo",
                  "ean13",
                  "nombre",
                  //"coste_unidad_venta",
                  "peso_unitario",
                  "familia_id",
                  "seccion_id",
                  "proveedor_id",
                  "marca_id",
                ],
              });
            }
            p.then(function (res) {
              if (parseInt(res.count)) {
                let a = res.data[0];
                self.cachedArticulosCodigo[a.codigo] = a;
                self.cachedArticulosEan13[a.ean13] = a;

                let error = "";
                switch (self.formData.seleccion) {
                  case "familia":
                    if (a.familia_id != self.formData.familia_id?.codigo) {
                      error =
                        "El artículo no pertenece a la familia seleccionada";
                    }
                    break;
                  case "seccion":
                    if (a.seccion_id != self.formData.seccion_id?.codigo) {
                      error =
                        "El artículo no pertenece a la seccion seleccionada";
                    }
                    break;
                  case "marca":
                    if (a.marca_id != self.formData.marca_id?.codigo) {
                      error =
                        "El artículo no pertenece a la marca seleccionada";
                    }
                    break;
                  case "proveedor":
                    if (a.proveedor_id != self.formData.proveedor_id?.codigo) {
                      error =
                        "El artículo no pertenece al proveedor seleccionado";
                    }
                    break;
                }
                if (error) {
                  self.app.toast(error, "error");
                  h.setDataAtRowProp(row, prop, oldVal, "auto");
                  return;
                }

                let d = h.getSourceData()[row];
                d.articulo_id = a;
                d.ean13 = a.ean13;
                d.descripcion = a.nombre;
                d.unidades = 1;
                d.peso = a.peso_unitario || 0;
                //d.precio = a.coste_unidad_venta || 0;

                self.renderH(h);
              } else {
                self.app.toast("No se han encontrado el artículo", "error");
                h.setDataAtRowProp(row, prop, oldVal, "auto");
              }
            }).catch(function (res) {
              window.console.log(res);
            });
          }
          if (prop == 'unidades' && oldVal != newVal) {
            h.getSourceData()[row].peso = oldVal ? newVal * val('peso') / oldVal : 0;
          }
        });
      }
    );

    self.$refs.lineas.field.hotInstance.addHook("beforeKeyDown", function (e) {
      var keyCode = e.keyCode || e.which;
      var arrow = { left: 37, up: 38, right: 39, down: 40, tab: 9 };
      switch (keyCode) {
        case arrow.left:
        case arrow.up:
        case arrow.right:
        case arrow.down:
        case arrow.tab:
          return;
      }
      var h = this;
      var sel = h.getSelected();
      if (typeof sel == "undefined") return;
      var r = sel[0][0];
      var p = h.colToProp(sel[0][1]);
      if (
        p != "ean13" &&
        p != "articulo_id" &&
        !h.getDataAtRowProp(r, "articulo_id")
      ) {
        h.selectCell(r, "articulo_id");
      }
    });

    self.renderH = self.$utils.misc.debounce(function (h) {
      self.$refs.lineas.field.hotInstance.render();
    }, 200);
  },
};
</script>